import React from 'react';
import propTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import GenericButton from '../GenericButton';

import styles from './styles.module.scss';
import { AmplitudeEvents } from '@anghami/neoanalytics/src/enums/events';
import reportAmplitudeEvent from '@helpers/analytics';
import { deeplinkIntoAppUsingBranch } from '@helpers/branch-helper';

export default function LoginWithApp({ redirectUrl = '' }) {
	const { query } = useRouter();
	const { t } = useTranslation();

	// show button only on phone and if redirectUrl is available
	if ((query?.redirecturl && query?.redirecturl_encoded) || redirectUrl) {
		const decodedUrl = atob((query?.redirecturl as string) || '');

		// checking for third party urls
		if (
			(decodedUrl && !decodedUrl.includes('anghami.com')) ||
			(redirectUrl && !redirectUrl.includes('anghami.com')) ||
			query.client_id === 'alexa-skill'
		) {
			return null;
		}
	}

	const handleButtonClick = () => {
		reportAmplitudeEvent(AmplitudeEvents.continueInApp, {});

		const loginWithAppURL =
			redirectUrl ||
			atob((query?.redirecturl as string) || '') ||
			'anghami.com' + window.location.pathname + window.location.search;
		const cleanedUrl = loginWithAppURL
		.replace('https://', '')
		.replace('http://', '')
		.replace('www.', '');

		deeplinkIntoAppUsingBranch(cleanedUrl, false, 'webview');
	};

	return (
		<div className={styles.LoginWithApp}>
			<GenericButton
				icon="anghami"
				className={styles['LoginWithApp-btn']}
				onClick={handleButtonClick}
			>
				{t('Login with app')}
			</GenericButton>
		</div>
	);
}

LoginWithApp.propTypes = {
	redirectUrl: propTypes.string
};
