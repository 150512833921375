import { authenticate, IAuthenticateRequest } from '@anghami/neogateway/dist/endpoints/authentication';
import { gateway } from './neogateway';

export const doAuthentication = async (type: string, user: IAuthenticateRequest) => {
	const body: any = {
		m: type
	};

	switch (type) {
		case 'gop':
			body.u = user.email;
			body.p = user.token;
			break;

		case 'fb':
			body.fid = user.userID;
			body.ftk = user.accessToken;
			break;

		case 'an':
			body.u = user.email || user.username;
			body.p = encodeURIComponent(user.password);
			break;

		case 'lc':
			body.u = user.userid;
			body.p = user.code;
			body.tv = true;
			body._magic = true;
			break;

		case 'ap':
			body.identitytoken = user.identitytoken;
			body.authorizationcode = user.authorizationcode;
			break;

		default:
			break;
	}

	return gateway.callEndpoint(authenticate, body);
};
