import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

import { handleGoogleLogin, loadGoogleSDK } from '../../../helpers/SDKs-loader';
import { doAuthentication } from '../../../helpers/authenticate';
import GenericButton from '../GenericButton';

import styles from './styles.module.scss';
import { AmplitudeEvents } from '@anghami/neoanalytics/src/enums/events';
import reportAmplitudeEvent from '@helpers/analytics';

export default function GoogleLogin({ onSuccess = (res) => res, onError = (error) => error }) {
	const [loading, setLoading] = useState(false);
	const [sdkLoading, setSDKLoading] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		// Toggle the loading indicator while the SDK is loading
		// Only appears if the SDK is not cached in the browser
		setSDKLoading(true);
		loadGoogleSDK()
		.then(() => {
			setSDKLoading(false);
		})
		.catch((error) => {
			onError(error);
		});
	}, []);

	const handleButtonClick = () => {
		setLoading(true);
		handleGoogleLogin()
		.then((res) => {
			doAuthentication('gop', res)
			.then(({ data: res }) => {
				setLoading(false);
				onSuccess(res);
				reportAmplitudeEvent(AmplitudeEvents.loggedInWithgoogle, {});
				reportAmplitudeEvent(AmplitudeEvents.loginSuccessful, {
					method: 'google'
				});
			})
			.catch((error) => {
				setLoading(false);
				onError(error);
				reportAmplitudeEvent(AmplitudeEvents.loginError, {
					method: 'google'
				});
			});
		})
		.catch((error) => {
			setLoading(false);
			onError(error);
		});
	};

	return (
		<div className={styles.GoogleLogin} id="google-root">
			<GenericButton
				icon="google"
				isLoading={loading}
				className={`${styles['GoogleLogin-btn']} ${
					sdkLoading && styles['GoogleLogin-btn-loading']
				}`}
				onClick={() => {
					if (sdkLoading) {
						return;
					}

					reportAmplitudeEvent(AmplitudeEvents.tapGoogleLogin, {});
					handleButtonClick();
				}}
			>
				{t('Continue with Google')}
			</GenericButton>
		</div>
	);
}

GoogleLogin.propTypes = {
	onSuccess: propTypes.func,
	onError: propTypes.func
};
