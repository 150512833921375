import React from 'react';
import propTypes from 'prop-types';

import RingLoading from '../../RingLoading';

import styles from './styles.module.scss';
import { environment } from '@environment';

export default function GenericButton({
	icon,
	isLoading = false,
	className = '',
	id = '',
	onClick,
	children
}) {
	let renderedIcon = null;

	switch (icon) {
		case undefined:
			break;

		case 'fb':
			renderedIcon = (
				<img src={`${environment.new_web_cdn_url}/web/facebook.svg`} alt="facebook logo" />
			);
			break;
		case 'google':
			renderedIcon = (
				<img src={`${environment.new_web_cdn_url}/web/google.svg`} alt="google logo" />
			);
			break;
		case 'phone':
			renderedIcon = (
				<img
					src={`${environment.new_web_cdn_url}/web/assets/icons/all/mobile.svg`}
					alt="phone logo"
				/>
			);
			break;
		case 'apple':
			renderedIcon = (
				<img
					src={`${environment.new_web_cdn_url}/web/assets/icons/all/apple.svg`}
					alt="apple logo"
				/>
			);
			break;
		case 'anghami':
			renderedIcon = (
				<img
					src={`${environment.images_cdn_url}/anghami-logo-white-notext.svg`}
					alt="anghami logo"
				/>
			);
			break;

		default:
			renderedIcon = 'notfound';
	}

	if (isLoading) {
		renderedIcon = <RingLoading />;
	}

	return (
		<div id={id} className={`${styles.GenericButton} ${className}`} onClick={onClick}>
			<div className={styles['GenericButton-icon']}>{renderedIcon}</div>
			{children}
		</div>
	);
}

GenericButton.propTypes = {
	className: propTypes.string,
	icon: propTypes.string,
	onClick: propTypes.func,
	children: propTypes.any,
	isLoading: propTypes.bool,
	id: propTypes.string
};
