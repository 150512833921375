import loadjs from 'loadjs';
import { environment } from '../env/environment';

const getSDKFromWindow = (varName): any => window[varName];

// ============= Amazon SDK =============

export const loadAmazonSDK = async () => {
	return new Promise<void>((resolve, reject) => {
		if (!process.browser) {
			reject();
			return;
		}

		// check if already loaded
		const amazon = getSDKFromWindow('amazon');
		if (amazon) {
			resolve();
			return;
		}

		loadjs(environment.amazon_sdk_url, {
			numRetries: 2,
			success: () => {
				getSDKFromWindow('amazon').Login.setClientId(environment.amazon_client_id);
				resolve();
			},
			error: reject
		});
	});
};

export const handleAmazonLogin = async () => {
	return new Promise((resolve, reject) => {
		if (!process.browser) {
			reject();
			return;
		}

		const scope = 'profile:contact prime:benefit_status';

		const options = {
			scope,
			popup: false,
			response_type: 'code'
		};

		// to force popup to show again
		getSDKFromWindow('amazon').Login.logout();

		// Crazy hack to get the authorize param for API to use
		const originalSend = XMLHttpRequest.prototype.send;
		XMLHttpRequest.prototype.send = function (data) {
			const jsonData = JSON.parse(data as string);
			resolve(jsonData);
			XMLHttpRequest.prototype.send = originalSend;
		};

		const region = getSDKFromWindow('amazon').Login.Region.Europe;
		getSDKFromWindow('amazon').Login.setRegion(region);

		// getSDKFromWindow('amazon').Login.authorize(options, (response) => {
		// 	if (response.error) {
		// 		reject(response);
		// 	}
		// });

		getSDKFromWindow('amazon').Login.authorize(
			options,
			window.location.href.includes('landing')
				? 'https://landing.anghami.com/amazonprime'
				: 'https://www.anghami.com/amazonprime'
		);
	});
};

// ============= Google SDK =============

const parseGoogleSDKResponse = (googleUser: any) => {
	return {
		email: googleUser.getBasicProfile().getEmail(),
		token: googleUser.getAuthResponse().access_token
	};
};

export const loadGoogleSDK = async () => {
	return new Promise<void>((resolve, reject) => {
		if (!process.browser) {
			reject();
			return;
		}

		// check if already loaded
		const googleSDK = getSDKFromWindow('gapi');
		if (googleSDK) {
			resolve();
			return;
		}

		loadjs(environment.google_sdk_url, {
			numRetries: 2,
			success: () => {
				getSDKFromWindow('gapi').load('auth2', () => {
					getSDKFromWindow('gapi')
					.auth2.init({
						client_id: environment.google_client_id,
						scope: 'email profile openid',
						prompt: 'select_account',
						cookiepolicy: 'single_host_origin',
						response_type: 'token',
						redirect_uri: 'https://play.anghami.com/login'
					})
					.then(resolve)
					.catch(reject);
				});
			},
			error: reject
		});
	});
};

export const handleGoogleLogin = async () => {
	return new Promise((resolve, reject) => {
		if (!process.browser) {
			reject();
			return;
		}

		const googleAuth = getSDKFromWindow('gapi').auth2.getAuthInstance();
		const isLoggedIn = googleAuth.isSignedIn.get();

		if (isLoggedIn) {
			googleAuth.signOut();
		}

		googleAuth
		.signIn()
		.then((googleUser) => {
			const parsedResponse = parseGoogleSDKResponse(googleUser);
			resolve(parsedResponse);
		})
		.catch((error) => {
			reject(error);
		});
	});
};

// ============= Facebook SDK =============

const parseFacebookSDKResponse = (response: any) => {
	return {
		userID: response.authResponse.userID,
		accessToken: response.authResponse.accessToken
	};
};

export const loadFacebookSDK = async () => {
	return new Promise<void>((resolve, reject) => {
		if (!process.browser) {
			reject();
			return;
		}

		// check if already loaded
		const facebookSDK = getSDKFromWindow('FB');
		if (facebookSDK) {
			resolve();
			return;
		}

		loadjs(environment.facebook_sdk_url, {
			numRetries: 2,
			success: () => {
				getSDKFromWindow('FB').init({
					appId: environment.facebook_app_id,
					status: true,
					version: 'v2.8',
					xfbml: false
				});
				resolve();
			},
			error: reject
		});
	});
};

export const handleFacebookLogin = async () => {
	return new Promise((resolve, reject) => {
		if (!process.browser) {
			reject();
			return;
		}

		getSDKFromWindow('FB').getLoginStatus((response) => {
			if (response.status === 'connected') {
				getSDKFromWindow('FB').logout();
			}

			getSDKFromWindow('FB').login(
				(response) => {
					if (response.status === 'connected') {
						const parsedResponse = parseFacebookSDKResponse(response);
						resolve(parsedResponse);
					} else {
						reject(response);
					}
				},
				{
					scope: 'public_profile,email,user_friends',
					return_scopes: true
				}
			);
		});
	});
};

// ============= Apple SDK =============

export const loadAppleSDK = async () => {
	return new Promise<void>((resolve, reject) => {
		if (!process.browser) {
			reject();
			return;
		}

		// check if already loaded
		const apple = getSDKFromWindow('AppleID');
		if (apple) {
			resolve();
			return;
		}

		loadjs(environment.apple_sdk_url, {
			numRetries: 2,
			success: resolve,
			error: reject
		});
	});
};

export const handleAppleLogin = async () => {
	return new Promise((resolve, reject) => {
		if (!process.browser) {
			reject();
			return;
		}

		getSDKFromWindow('AppleID').auth.init({
			clientId: environment.apple_client_id,
			scope: 'name email',
			redirectURI: 'https://www.anghami.com/applesignin-redirect',
			usePopup: true
		});

		document.addEventListener('AppleIDSignInOnSuccess', (data) => {
			resolve(data);
		});
		// Listen for authorization failures
		document.addEventListener('AppleIDSignInOnFailure', (error) => {
			reject(error);
		});

		return getSDKFromWindow('AppleID')
		.auth.signIn()
		.then((res) => {
			resolve(res);
		})
		.catch((err) => {
			reject(err);
		});
	});
};
