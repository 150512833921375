import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

import { handleFacebookLogin, loadFacebookSDK } from '../../../helpers/SDKs-loader';
import { doAuthentication } from '../../../helpers/authenticate';
import GenericButton from '../GenericButton';

import styles from './styles.module.scss';
import { AmplitudeEvents } from '@anghami/neoanalytics/src/enums/events';
import reportAmplitudeEvent from '@helpers/analytics';

export default function FacebookLogin({ onSuccess = (res) => res, onError = (error) => error }) {
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	const handleButtonClick = () => {
		setLoading(true);
		loadFacebookSDK()
		.then(() => {
			handleFacebookLogin()
			.then((res) => {
				doAuthentication('fb', res)
				.then(({ data: res }) => {
					setLoading(false);
					onSuccess(res);
					reportAmplitudeEvent(AmplitudeEvents.loggedInWithfacebook, {});
					reportAmplitudeEvent(AmplitudeEvents.loginSuccessful, {
						method: 'facebook'
					});
				})
				.catch((error) => {
					setLoading(false);
					onError(error);
					reportAmplitudeEvent(AmplitudeEvents.loginError, {
						method: 'facebook'
					});
				});
			})
			.catch((error) => {
				setLoading(false);
				onError(error);
			});
		})
		.catch(() => {
			setLoading(false);
			onError(null);
		});
	};

	return (
		<div className={styles.FacebookLogin} id="fb-root">
			<GenericButton
				icon="fb"
				isLoading={loading}
				className={styles['FacebookLogin-btn']}
				onClick={() => {
					reportAmplitudeEvent(AmplitudeEvents.tapFacebookLogin, {});
					handleButtonClick();
				}}
			>
				{t('Continue with Facebook')}
			</GenericButton>
		</div>
	);
}

FacebookLogin.propTypes = {
	onSuccess: propTypes.func,
	onError: propTypes.func
};
