import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import QRCode from 'react-qr-code';
import { useTranslation } from 'next-i18next';

import { environment } from '../../../env/environment';
import getDeviceCode from '@anghami/neogateway/dist/endpoints/getDeviceCode';
import useInterval from '../../../hooks/useInterval';
import { doAuthentication } from '../../../helpers/authenticate';

import styles from './styles.module.scss';
import RingLoading from '@components/RingLoading';
import { AmplitudeEvents } from '@anghami/neoanalytics/src/enums/events';
import reportAmplitudeEvent from '@helpers/analytics';
import { gateway } from '@helpers/neogateway';

export default function QRLogin({
	onModalChange,
	onSuccess = (res) => res,
	onError = (error) => error
}) {
	const [showPopup, setShowPopup] = useState(false);
	const [deviceCode, setDeviceCode] = useState(null);
	const [loadingAuth, setLoadingAuth] = useState(false);
	const { t } = useTranslation();

	useEffect(() => onModalChange(showPopup), [showPopup]);

	useInterval(() => {
		if (!showPopup) {
			return;
		}

		gateway
		.callEndpoint(getDeviceCode, {
			udid: '',
			cacheBust: Date.now(),
			c: window.navigator.userAgent
		})
		.then(({ data: res }) => {
			setDeviceCode(res);
			if (res.userid) {
				setLoadingAuth(true);
				doAuthentication('lc', {
					userid: res.userid,
					code: res.code
				})
				.then(({ data: res }) => {
					setLoadingAuth(false);
					setShowPopup(false);
					onSuccess(res);
					reportAmplitudeEvent(AmplitudeEvents.loggedInWithQRLogin, {});
					reportAmplitudeEvent(AmplitudeEvents.loginSuccessful, {
						method: 'qrcode'
					});
				})
				.catch((e) => {
					reportAmplitudeEvent(AmplitudeEvents.loginError, {
						method: 'qrcode'
					});
					onError(e);
				});
			}
		});
	}, 2000);

	return (
		<>
			<div className={styles.QRLogin}>
				<div className={styles['QRLogin-img']}>
					<img
						src={`${environment.new_web_cdn_url}/web/assets/icons/all/QRCode.svg`}
						alt="qrcode"
					/>
				</div>
				<div className={styles['QRLogin-txt']}>
					<p>{t('login_code_text_first')}</p>
					<p>{t('login_code_text_second')}</p>
				</div>
				<div
					className={styles['QRLogin-btn']}
					onClick={() => {
						reportAmplitudeEvent(AmplitudeEvents.tapQRLogin, {});
						setShowPopup(true);
					}}
				>
					{t('login_code_button')}
				</div>
			</div>

			{showPopup && (
				<>
					<div className={styles['QRLogin-popup'] + ' animate-fadeIn'}>
						<img
							className={styles['QRLogin-popup-logo']}
							alt="Anghami"
							src={environment.images_cdn_url + '/anghami-logo-black.png'}
						/>

						<p className={styles['QRLogin-popup-title']}>{t('login_code_howto_title')}</p>

						<div className={styles['QRLogin-popup-wrapper']}>
							<div className={styles['QRLogin-popup-wrapper-help']}>
								<ol className={styles['QRLogin-popup-wrapper-help-list']}>
									<li>{t('login_code_howto_step1')}</li>
									<li>{t('Connect devices steps')}</li>
									<li>{t('login_code_howto_step3')}</li>
								</ol>
							</div>

							<div className={styles['QRLogin-popup-wrapper-qr']}>
								{deviceCode ? (
									<>
										<QRCode value={'DEVICE_' + deviceCode.code} size={203} level="Q" />
										<div
											className={`${styles['QRLogin-popup-wrapper-qr-logo']} ${
												loadingAuth && styles['QRLogin-popup-wrapper-qr-logo-auth-loading']
											}`}
										>
											{loadingAuth ? (
												<RingLoading isBlack />
											) : (
												<img
													src={environment.images_cdn_url + '/anghami-logo-black-notext.png'}
													alt="anghami logo"
												/>
											)}
										</div>
									</>
								) : (
									<div className={styles['QRLogin-popup-wrapper-qr-loading']} />
								)}
							</div>
						</div>

						<p
							className={styles['QRLogin-popup-back-btn']}
							onClick={() => {
								setShowPopup(false);
								setDeviceCode(null);
							}}
						>
							{t('back')}
						</p>
					</div>
					<div
						className={styles['QRLogin-popup-overlay']}
						onClick={() => {
							setShowPopup(false);
							setDeviceCode(null);
						}}
					/>
				</>
			)}
		</>
	);
}

QRLogin.propTypes = {
	onModalChange: propTypes.func,
	onSuccess: propTypes.func,
	onError: propTypes.func
};
