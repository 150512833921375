import React, { useState } from 'react';
import propTypes from 'prop-types';
import styles from './otpinput.module.scss';

enum Keys {
	Backspace = 'Backspace',
	zero = 0,
	nine = 9
}

const OTPInput = ({ onSubmit }) => {
	const [otpValue, setOtpValue] = useState({
		0: '',
		1: '',
		2: '',
		3: ''
	});

	const handleOnInputPress = (e) => {
		const { target, key } = e;
		if (e.key === Keys.Backspace) {
			if (target.previousSibling) {
				target.previousSibling.focus();
			}

			setOtpValue({
				...otpValue,
				[target.dataset.index]: ''
			});
		} else if (key >= Keys.zero || key <= Keys.nine) {
			const newOTPValue = {
				...otpValue,
				[target.dataset.index]: key
			};

			setOtpValue(newOTPValue);

			if (target.nextSibling) {
				target.nextSibling.focus();
			} else {
				target.blur();
				onSubmit(Object.values(newOTPValue).join(''));
			}
		}
	};

	const handleOnChange = () => null;

	const handleOnFocus = (e) => {
		e.target.select();
	};

	return (
		<div className={styles.OTPInput}>
			<input
				autoFocus
				maxLength={1}
				data-index={0}
				type="tel"
				value={otpValue[0]}
				onFocus={handleOnFocus}
				onChange={handleOnChange}
				onKeyUp={handleOnInputPress}/>
			<input
				maxLength={1}
				type="tel"
				data-index={1}
				value={otpValue[1]}
				onFocus={handleOnFocus}
				onChange={handleOnChange}
				onKeyUp={handleOnInputPress}/>
			<input
				maxLength={1}
				type="tel"
				data-index={2}
				value={otpValue[2]}
				onFocus={handleOnFocus}
				onChange={handleOnChange}
				onKeyUp={handleOnInputPress}/>
			<input
				maxLength={1}
				type="tel"
				data-index={3}
				value={otpValue[3]}
				onFocus={handleOnFocus}
				onChange={handleOnChange}
				onKeyUp={handleOnInputPress}/>
		</div>
	);
};

OTPInput.propTypes = {
	onSubmit: propTypes.func
};

export default OTPInput;
