import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

import { environment } from '../../../env/environment';
import postEmailExists from '@anghami/neogateway/dist/endpoints/postEmailExists';
import { registerUser } from '@anghami/neogateway/dist/endpoints/registerUser';
import postSendConfirmationEmail from '@anghami/neogateway/dist/endpoints/postSendConfirmationEmail';
import { doAuthentication } from '../../../helpers/authenticate';
import GenericButton from '../GenericButton';

import styles from './styles.module.scss';
import reportAmplitudeEvent from '@helpers/analytics';
import { AmplitudeEvents } from '@anghami/neoanalytics/src/enums/events';
import { gateway } from '@helpers/neogateway';

enum Steps {
	error = 0,
	loginDetailsInput = 1,
	signupPasswordInputs = 2,
	signupAccountData = 3,
	signupCheckYourEmail = 4,
	loginDialog = 5
}

export default function LocalLogin({
	onModalChange,
	onSuccess = (res) => res,
	onError = (error) => error
}) {
	const [step, setStep] = useState(null);
	const [showPopup, setShowPopup] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [rePassword, setRePassword] = useState('');
	const [avatarFile, setAvatarFile] = useState(null);
	const [fullname, setFullname] = useState('');
	const [birthday, setBirthday] = useState('');
	const [gender, setGender] = useState('');
	const [emailError, setEmailError] = useState(null);
	const [passwordError, setPasswordError] = useState(null);
	const [loading, setLoading] = useState(false);
	const {
		t,
		i18n: { language: lang }
	} = useTranslation();
	const [inputsErrors, setInputsErrors] = useState<any>({});
	const [dialog, setDialog] = useState(null);
	const [message, setMessage] = useState('');
	let renderStep = null;

	const handleEmailSubmit = (e) => {
		e.preventDefault();

		if (email.length < 3) {
			setEmailError({
				message: ''
			});
			return;
		}

		reportAmplitudeEvent(AmplitudeEvents.tapEmailLogin, {});

		setLoading(true);
		gateway
		.callEndpoint(postEmailExists, { email })
		.then(({ data: res }) => {
			setLoading(false);
			if (res?.exists) {
				setShowPopup(true);
				setStep(Steps.loginDetailsInput);
			} else {
				setShowPopup(true);
				setStep(Steps.signupPasswordInputs);
			}
		})
		.catch((error) => {
			setLoading(false);
			setEmailError(error);
		});
	};

	const handleDetailsSubmit = (e) => {
		e.preventDefault();

		if (email.length < 3) {
			setEmailError({
				message: ''
			});
			return;
		}

		if (password.length < 5 || password.length > 128) {
			setPasswordError({
				message: ''
			});
			return;
		}

		setLoading(true);
		doAuthentication('an', {
			email,
			password
		})
		.then(({ data: res }) => {
			setLoading(false);

			if (res.error) {
				if (res.dialog) {
					setDialog(res.dialog);
					setStep(Steps.loginDialog);
					return;
				}

				setEmailError(res.error);
				return;
			}

			setShowPopup(false);
			onSuccess(res);
			reportAmplitudeEvent(AmplitudeEvents.loggedInWithemail, {});
			reportAmplitudeEvent(AmplitudeEvents.loginSuccessful, {
				method: 'email'
			});
		})
		.catch((e) => {
			console.log('--Local login auth err', e);
			setLoading(false);
			onError(e);
			reportAmplitudeEvent(AmplitudeEvents.loginError, {
				method: 'email'
			});
		});
	};

	const handlePasswordsSubmit = (e) => {
		e.preventDefault();

		if (password.length === 0 || rePassword.length === 0) {
			setInputsErrors({
				...inputsErrors,
				password: true,
				rePassword: true
			});
			return;
		}

		if (inputsErrors.password || inputsErrors.rePassword) {
			return;
		}

		setStep(Steps.signupAccountData);
	};

	const getUserAvatar = () =>
		avatarFile
			? URL.createObjectURL(avatarFile)
			: `${environment.phoenix_url}/adminupload/NoGender.jpeg`;
	const isGenderInvalid = () => gender.length < 1;
	const handleUserInfoSubmit = (e) => {
		e.preventDefault();
		if (fullname.length === 0 || birthday.length === 0) {
			setInputsErrors({
				...inputsErrors,
				firstname: fullname.length === 0,
				birthday: birthday.length === 0
			});
			return;
		}

		if (inputsErrors.fullname || inputsErrors.birthday) {
			return;
		}

		const age = new Date().getUTCFullYear() - new Date(birthday).getUTCFullYear();
		const params = {
			email,
			password,
			gender,
			m: 'an',
			firstname: fullname.split(' ')[0],
			lastname: fullname.split(' ')[1],
			age
		};

		setLoading(true);
		gateway
		.callEndpoint(registerUser, params)
		.then(({ data: res }: any) => {
			if (res.error) {
				setLoading(false);
				setEmailError({ message: res.error?._attributes?.message || res?.error?.message });
			} else {
				setLoading(false);
				setStep(Steps.signupCheckYourEmail);
			}
		})
		.catch(() => {
			setLoading(false);
		});
	};

	const handleInputsChange = (inputName: string) => {
		switch (inputName) {
			case 'password':
				setInputsErrors({
					...inputsErrors,
					password: password.length < 5 || password.length > 128
				});
				break;
			case 'rePassword':
				setInputsErrors({
					...inputsErrors,
					rePassword: rePassword !== password
				});
				break;
			case 'fullname':
				setInputsErrors({
					...inputsErrors,
					fullname: fullname.length < 2
				});
				break;
			case 'birthday':
				setInputsErrors({
					...inputsErrors,
					birthday: birthday.length < 2
				});
				break;
			default:
		}
	};

	const handleResetActivationEmail = () => {
		setLoading(true);
		gateway
		.callEndpoint(postSendConfirmationEmail, { email })
		.then(({ data: res }) => {
			setLoading(false);
			setMessage(res.message);
			setStep(Steps.signupCheckYourEmail);
		})
		.catch(() => {
			setLoading(false);
		});
	};

	useEffect(() => {
		onModalChange(showPopup);
		setPassword('');
		setRePassword('');
	}, [showPopup]);

	switch (step) {
		case Steps.loginDetailsInput:
			renderStep = (
				<div className={`${styles['LocalLogin-inputs']} ${emailError && 'error'}`}>
					<form
						className={styles['LocalLogin-inputs-form']}
						onSubmit={(e) => {
							handleDetailsSubmit(e);
						}}
					>
						<input
							required
							id="local_login_email2"
							className={`${styles['LocalLogin-inputs-form-input']} ${emailError && 'error'}`}
							type="email"
							placeholder={t('enter your email')}
							value={email}
							onChange={(e) => {
								setEmailError(null);
								setEmail(e.target.value);
							}}
						/>
						<input
							required
							autoFocus
							id="local_login_password"
							className={`${styles['LocalLogin-inputs-form-input']} ${passwordError && 'error'}`}
							type="password"
							placeholder={t('enter your password')}
							value={password}
							onChange={(e) => {
								setEmailError(null);
								setPasswordError(null);
								setPassword(e.target.value);
							}}
							onKeyUp={(_e) => {
								if (_e.keyCode === 13) {
									handleDetailsSubmit(_e);
								}
							}}
						/>
						<input type="submit" className={styles['LocalLogin-inputs-form-input-hidden']} />
					</form>
					{emailError?.message && (
						<p className={styles['LocalLogin-inputs-error-message']}>{emailError.message}</p>
					)}
					<GenericButton
						id="local_login_submit2"
						isLoading={loading}
						className={styles['LocalLogin-popup-btn']}
						onClick={handleDetailsSubmit}
					>
						{t('continue')}
					</GenericButton>
				</div>
			);
			break;

		case Steps.signupPasswordInputs:
			renderStep = (
				<div className={`${styles['LocalLogin-inputs']} ${emailError && 'error'}`}>
					<h2 className={styles['LocalLogin-popup-heading']}>{t('Create a new account')}</h2>
					<p className={styles['LocalLogin-popup-title']}>{email}</p>
					<form className={styles['LocalLogin-inputs-form']} onSubmit={handlePasswordsSubmit}>
						<input
							required
							autoFocus
							className={`${styles['LocalLogin-inputs-form-input']} ${
								inputsErrors.password && styles['LocalLogin-inputs-form-input-error']
							}`}
							type="password"
							placeholder={t('enter your password')}
							value={password}
							onChange={(e) => {
								setPassword(e.target.value);
							}}
							onBlur={() => {
								handleInputsChange('password');
							}}
						/>

						<input
							required
							className={`${styles['LocalLogin-inputs-form-input']} ${
								inputsErrors.rePassword && styles['LocalLogin-inputs-form-input-error']
							}`}
							type="password"
							placeholder={t('Confirm your password')}
							value={rePassword}
							onChange={(e) => {
								setRePassword(e.target.value);
							}}
							onBlur={() => {
								handleInputsChange('rePassword');
							}}
						/>
						<input type="submit" className={styles['LocalLogin-inputs-form-input-hidden']} />
					</form>
					{emailError?.message && (
						<p className={styles['LocalLogin-inputs-error-message']}>{emailError.message}</p>
					)}
					<GenericButton
						isLoading={loading}
						className={styles['LocalLogin-popup-btn']}
						onClick={handlePasswordsSubmit}
					>
						{t('Create a new account')}
					</GenericButton>
				</div>
			);
			break;

		case Steps.signupAccountData:
			renderStep = (
				<div className={`${styles['LocalLogin-inputs']} ${emailError && 'error'}`}>
					<h2 className={styles['LocalLogin-popup-heading']}>{t('Complete your profile')}</h2>
					<form className={styles['LocalLogin-inputs-form']} onSubmit={handleUserInfoSubmit}>
						<label>
							<div className={styles['LocalLogin-popup-avatar']}>
								<img src={getUserAvatar()} />
								<input
									id="avatar-file"
									type="file"
									onChange={(e) => {
										setAvatarFile(e.target.files[0]);
									}}
								/>
							</div>
						</label>

						<label className={styles['LocalLogin-popup-label']}>{t('Your full name')}</label>
						<input
							required
							className={`${styles['LocalLogin-inputs-form-input']} ${
								inputsErrors.fullname && styles['LocalLogin-inputs-form-input-error']
							}`}
							type="text"
							placeholder={t('Enter your full name')}
							value={fullname}
							onChange={(e) => {
								setFullname(e.target.value);
							}}
							onBlur={() => {
								handleInputsChange('fullname');
							}}
						/>

						<label className={styles['LocalLogin-popup-label']}>{t('Your date of birth')}</label>
						<input
							required
							className={`${styles['LocalLogin-inputs-form-input']} ${
								inputsErrors.birthday && styles['LocalLogin-inputs-form-input-error']
							}`}
							type="date"
							value={birthday}
							onChange={(e) => {
								setBirthday(e.target.value);
							}}
							onBlur={() => {
								handleInputsChange('birthday');
							}}
						/>

						<div
							className={`${styles['LocalLogin-popup-radio-wrapper']} ${
								isGenderInvalid() && styles['LocalLogin-inputs-form-input-error']
							}`}
							onChange={(e: any) => {
								setGender(e.target.value);
							}}
						>
							<label className={styles['LocalLogin-popup-label']}>{t('Gender')}</label>
							<label htmlFor="male-radio" className={styles['LocalLogin-popup-radio']}>
								<input required id="male-radio" type="radio" name="gender" value="male" />
								<span className={styles['LocalLogin-popup-radio-custom']}>
									<span />
								</span>
								<span>{t('Male')}</span>
							</label>
							<label htmlFor="female-radio" className={styles['LocalLogin-popup-radio']}>
								<input required id="female-radio" type="radio" name="gender" value="female" />
								<span className={styles['LocalLogin-popup-radio-custom']}>
									<span />
								</span>
								<span>{t('Female')}</span>
							</label>
						</div>

						<input type="submit" className={styles['LocalLogin-inputs-form-input-hidden']} />
					</form>
					{emailError?.message && (
						<p className={styles['LocalLogin-inputs-error-message']}>{emailError.message}</p>
					)}
					<GenericButton
						isLoading={loading}
						className={styles['LocalLogin-popup-btn']}
						onClick={handleUserInfoSubmit}
					>
						{t('Create a new account')}
					</GenericButton>
				</div>
			);
			break;

		case Steps.signupCheckYourEmail:
			renderStep = (
				<p className={styles['LocalLogin-popup-title']}>
					{message || t('We sent you an email. Please check your inbox to validate your email.')}
				</p>
			);
			break;

		case Steps.loginDialog:
			renderStep = (
				<>
					<h2 className={styles['LocalLogin-popup-heading']}>{dialog.title}</h2>
					<p className={styles['LocalLogin-popup-title']}>{dialog.subtitle}</p>
					<GenericButton
						className={styles['LocalLogin-popup-btn']}
						onClick={() => {
							handleResetActivationEmail();
						}}
					>
						{dialog.cancelbuttontext}
					</GenericButton>

					<GenericButton
						className={styles['LocalLogin-popup-btn-empty']}
						onClick={() => {
							setDialog(null);
							setStep(Steps.loginDetailsInput);
						}}
					>
						{dialog.buttontext}
					</GenericButton>
				</>
			);
			break;

		default:
			renderStep = <p>Invalid step</p>;
	}

	return (
		<>
			<div className={styles.LocalLogin}>
				<div className={`${styles['LocalLogin-inputs']} ${emailError && 'error'}`}>
					<form className={styles['LocalLogin-inputs-form']} onSubmit={handleEmailSubmit}>
						<input
							required
							autoFocus
							id="local_login_email"
							className={`${styles['LocalLogin-inputs-form-input']} ${emailError && 'error'}`}
							type="email"
							placeholder={t('enter your email')}
							value={email}
							onChange={(e) => {
								setEmailError(null);
								setEmail(e.target.value);
							}}
						/>
					</form>
				</div>
				{emailError?.message && (
					<p className={styles['LocalLogin-inputs-error-message']}>{emailError.message}</p>
				)}
				<GenericButton
					id="local_login_submit"
					isLoading={loading}
					className={styles['LocalLogin-popup-btn']}
					onClick={handleEmailSubmit}
				>
					{t('continue')}
				</GenericButton>
			</div>

			{showPopup && (
				<>
					<div className={styles['LocalLogin-popup'] + ' animate-fadeIn'}>
						<img
							className={styles['LocalLogin-popup-logo']}
							alt="Anghami"
							src={`${environment.web_cdn_url}web/assets/img/${
								lang === 'ar'
									? 'logos/NEW_LOGO_HORIZONTAL_AR.png'
									: 'new-anghami-logos/header-logo-fullblack.png'
							}`}
						/>

						{renderStep}

						<p
							className={styles['LocalLogin-popup-back-btn']}
							onClick={() => {
								setShowPopup(false);
							}}
						>
							{t('back')}
						</p>
					</div>
					<div
						className={styles['LocalLogin-popup-overlay']}
						onClick={() => {
							setShowPopup(false);
						}}
					/>
				</>
			)}
		</>
	);
}

LocalLogin.propTypes = {
	onModalChange: propTypes.func,
	onSuccess: propTypes.func,
	onError: propTypes.func
};
