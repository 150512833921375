import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

import { environment } from '../../env/environment';

import styles from './styles.module.scss';

import GoogleLogin from '../LoginButtons/GoogleLogin';
import FacebookLogin from '../LoginButtons/FacebookLogin';
import PhoneLogin from '../LoginButtons/PhoneLogin';
import LocalLogin from '../LoginButtons/LocalLogin';
import QRLogin from '../LoginButtons/QRLogin';
import AppleLogin from '../LoginButtons/AppleLogin';
import { isMobile } from '../../helpers/helper';
import LoginWithApp from '../LoginButtons/LoginWithApp';
import reportAmplitudeEvent from '@helpers/analytics';
import { AmplitudeEvents } from '@anghami/neoanalytics/src/enums/events';
import { useRouter } from 'next/router';
import { gateway } from '@helpers/neogateway';

export const LoginPopup = ({ params, onClosePopUp = () => null, onAuth }) => {
	const [hideModal, setHideModal] = useState(false);
	const [hideSubModal, setHideSubModal] = useState(true);
	const { t, i18n } = useTranslation();
	const lang = i18n.language;
	const router = useRouter();

	useEffect(() => {
		gateway.loadSodium();
		reportAmplitudeEvent(AmplitudeEvents.viewLoginScreen, {});
	}, [hideModal]);

	return (
		<>
			<div
				id="login_popup"
				className={`${styles.LoginPopup} ${
					hideModal && styles['LoginPopup-hidden']
				} animate-fadeIn`}
			>
				<img
					className={styles['LoginPopup-logo']}
					alt="Anghami"
					src={`${environment.web_cdn_url}web/assets/img/${
						lang === 'ar'
							? 'logos/NEW_LOGO_HORIZONTAL_AR.png'
							: 'new-anghami-logos/header-logo-fullblack.png'
					}`}
				/>

				{hideSubModal ? (
					<>
						{router.route !== '/amazonprime' && (
							<div className={styles['LoginPopup-loginwithapp']}>
								<LoginWithApp />
								<div className={styles['LoginPopup-divider']} />
								<p className={styles['LoginPopup-loginwithapp-subtitle']}>Or</p>
							</div>
						)}
						<GoogleLogin onSuccess={onAuth} onError={console.log} />
						<FacebookLogin onSuccess={onAuth} onError={console.log} />
						<PhoneLogin
							queryParams={params}
							onModalChange={(modalState) => {
								setHideModal(modalState);
							}}
							onSuccess={onAuth}
							onError={console.log}
						/>
						<p
							id="popup_other_login_options"
							className={styles['LoginPopup-otherlogin-link']}
							onClick={() => {
								setHideSubModal(false);
								reportAmplitudeEvent(AmplitudeEvents.moreLoginOptions, {});
							}}
						>
							{t('Other login options')}
						</p>
					</>
				) : (
					<>
						<AppleLogin onSuccess={onAuth} onError={console.log} />
						<div className={styles['LoginPopup-divider']} />
						<p className={styles['LoginPopup-loginwithapp-subtitle']}>
							{t('or continue by email')}
						</p>
						<LocalLogin
							onModalChange={(modalState) => {
								setHideModal(modalState);
							}}
							onSuccess={onAuth}
							onError={console.log}
						/>
						<p
							className={styles['LoginPopup-back-btn']}
							onClick={() => {
								setHideSubModal(true);
							}}
						>
							{t('back')}
						</p>
					</>
				)}

				<div className={styles['LoginPopup-divider']} />

				{!isMobile() && (
					<QRLogin
						onModalChange={(modalState) => {
							setHideModal(modalState);
						}}
						onSuccess={onAuth}
						onError={console.log}
					/>
				)}

				<div className={styles['LoginPopup-divider']} />

				<p className={styles['LoginPopup-recaptcha-notice']}>
					This site is protected by reCAPTCHA and the Google{' '}
					<a target="_blank" rel="noreferrer" href="https://policies.google.com/privacy">
						Privacy Policy
					</a>{' '}
					and{' '}
					<a target="_blank" rel="noreferrer" href="https://policies.google.com/terms">
						Terms of Service
					</a>{' '}
					apply.
				</p>
			</div>

			<div className={styles['LoginPopup-overlay']} onClick={() => onClosePopUp()} />
		</>
	);
};

LoginPopup.propTypes = {
	params: propTypes.object,
	onClosePopUp: propTypes.func,
	onAuth: propTypes.func.isRequired
};

export default LoginPopup;
