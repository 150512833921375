import React from 'react';
import propTypes from 'prop-types';

import styles from './styles.module.scss';

export default function RingLoading({ isBlack = false }) {
	return (
		<div className={`${styles.RingLoading} ${isBlack && styles['RingLoading-black']}`}>
			<div/>
			<div/>
			<div/>
			<div/>
		</div>
	);
}

RingLoading.propTypes = {
	isBlack: propTypes.bool
};
