import React, { useEffect, useState } from 'react';
import { userContext, Auth as IAuth } from './auth.context';
import LoginPopup from '../login-popup';
import { setCookie, destroyCookie, parseCookies } from 'nookies';
import getUserOwnProfileData from '@anghami/neogateway/dist/endpoints/getOwnProfileData';
import postLogout from '@anghami/neogateway/dist/endpoints/postLogout';
import { gateway } from '@helpers/neogateway';
import { useRouter } from 'next/router';
import { AmplitudeEvents } from '@enums/amplitude';
import reportAmplitudeEvent from '@helpers/analytics';
import { clearQsFromUrl } from '@helpers/clean-url';
import { IAuthenticateResponse } from '@anghami/neogateway/dist/endpoints/authentication';
import AccountRecoverModal from '@components/account-recover-modal';
import cookie from '@helpers/cookies';

export const setAppCookies = (name: string, value: any) => {
	setCookie(null, name, value, {
		maxAge: 30 * 24 * 60 * 60 * 60,
		domain: '.angha.me',
		path: '/'
	});
	setCookie(null, name, value, {
		maxAge: 30 * 24 * 60 * 60 * 60,
		domain: '.anghami.com',
		path: '/'
	});
};

interface IProps {
	children: any;
	userProfile: any;
	SID: string;
	sss: string;
}

const withAuth = (WrappedComponent: any) => {
	return (props: IProps) => {
		let isLoggedIn = false;

		if (props.userProfile?.id) {
			isLoggedIn = true;
		} else {
			isLoggedIn = false;
		}

		const [showLoginModal, setShowLoginModal] = useState(false);
		const [showAccountRecoverModal, setShowAccountRecoverModal] = useState(false);
		const [loggedIn, setLoggedIn] = useState(isLoggedIn);
		const [user, setUser] = useState(props.userProfile);
		const router = useRouter();

		useEffect(() => {
			// retrying to auth in the client if it failed in the server
			if (gateway && !loggedIn && typeof window !== 'undefined') {
				gateway.callEndpoint(getUserOwnProfileData).then((response) => {
					const user = response.data;
					if (user?.id) {
						setUser(user);
						setLoggedIn(true);
					}
				});
			}

			const shouldRecover = Boolean(cookie.getCookie('should_recover'));
			setShowAccountRecoverModal(shouldRecover);
		}, [props.sss, props.SID]);

		const handleOnAuth = (authResponse: IAuthenticateResponse) => {
			const { authenticate } = authResponse;
			setUser({
				...authenticate,
				// Append missing data from the auth response
				country: authenticate.usercountry,
				image: authenticate.picture
			});

			setAppCookies('loggedIn', true);
			setAppCookies('sss', authenticate.sss);
			setAppCookies('appsidsave', authenticate.socketsessionid);
			setAppCookies('firstauth', true);
			setLoggedIn(true);
			setShowLoginModal(false);
			setAppCookies('should_recover', '');

			if (authenticate.should_recover) {
				setAppCookies('should_recover', true);
				setShowAccountRecoverModal(true);
				return;
			}

			// to force the current page to re-call get initial props that used to be called in the server
			// https://github.com/vercel/next.js/issues/1982#issuecomment-301892234
			router.push(router.asPath, '');
			window.location.href = String(window.location.href);
		};

		// eslint-disable-next-line
		const auth: IAuth = {
			loggedIn,
			setLoggedIn,
			user,
			setUser,
			setLoginModalState: setShowLoginModal,
			loginModalState: showLoginModal,
			openLoginModal: () => {
				setShowLoginModal(true);
			},
			logout: async () => {
				const cookie = parseCookies(null);
				Object.keys(cookie).forEach((cookieKey) => {
					destroyCookie(null, cookieKey);
				});
				setLoggedIn(false);
				setUser(null);
				setAppCookies('loggedIn', false);
				setAppCookies('should_recover', '');
				reportAmplitudeEvent(AmplitudeEvents.signOut, {});
				window.localStorage.removeItem('user');
				await gateway.callEndpoint(postLogout, { output: 'jsonhp' });

				// to force the current page to re-call get initial props that used to be called in the server
				// https://github.com/vercel/next.js/issues/1982#issuecomment-301892234
				router.push(router.asPath, '');

				window.location.href = clearQsFromUrl(String(window.location.href));
			},
			accountRecoverModalState: showAccountRecoverModal,
			showAccountRecoverModal: (state) => {
				setShowAccountRecoverModal(state);
				if (!state) {
					setAppCookies('should_recover', '');
					destroyCookie(null, 'should_recover');
				}
			}
		};

		return (
			<userContext.Provider value={auth}>
				{showLoginModal && (
					<LoginPopup
						params={router?.query}
						onClosePopUp={() => {
							setShowLoginModal(false);
						}}
						onAuth={handleOnAuth}
					/>
				)}
				{showAccountRecoverModal && <AccountRecoverModal />}
				<WrappedComponent {...props} />
			</userContext.Provider>
		);
	};
};

export default withAuth;
