import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

import GenericButton from '../GenericButton';

import { handleAppleLogin, loadAppleSDK } from '../../../helpers/SDKs-loader';
import { doAuthentication } from '../../../helpers/authenticate';

import styles from './styles.module.scss';
import { AmplitudeEvents } from '@enums/amplitude';
import reportAmplitudeEvent from '@helpers/analytics';

const AMPLITUDE_EVENTS = {
	LOGIN_SUCCESS: 'Login successful',
	LOGIN_FAIL: 'Login error'
};

export default function Apple({ onSuccess = (res) => res, onError = (error) => error }) {
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	const handleButtonClick = () => {
		reportAmplitudeEvent(AmplitudeEvents.tapAppleLogin, {});

		setLoading(true);

		loadAppleSDK()
		.then(() => {
			handleAppleLogin()
			.then((res: any) => {
				doAuthentication('ap', {
					identitytoken: res.detail?.authorization.id_token,
					authorizationcode: res.detail?.authorization.code
				})
				.then(({ data: res }) => {
					setLoading(false);
					onSuccess(res);
					reportAmplitudeEvent(AmplitudeEvents.loggedInWithapple, {});
					reportAmplitudeEvent(AMPLITUDE_EVENTS.LOGIN_SUCCESS, {
						method: 'apple'
					});
				})
				.catch((error) => {
					setLoading(false);
					onError(error);
					reportAmplitudeEvent(AMPLITUDE_EVENTS.LOGIN_FAIL, {
						method: 'apple'
					});
				});
			})
			.catch((error) => {
				onError(error);
				setLoading(false);
			});
		})
		.catch(() => {
			setLoading(false);
			onError(null);
		});
	};

	return (
		<div className={styles.AppleLogin} id="apple-root">
			<GenericButton
				icon="apple"
				isLoading={loading}
				className={styles['AppleLogin-btn']}
				onClick={handleButtonClick}
			>
				{t('Continue with Apple')}
			</GenericButton>
		</div>
	);
}

Apple.propTypes = {
	onSuccess: propTypes.func,
	onError: propTypes.func
};
