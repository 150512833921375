import { Branch } from '@anghami/neoanalytics/dist/sdks/branch';

let _branch: Branch;

export type DeeplinkAction = Parameters<Branch['getBranchLink']>[2];

const getBranchDeepLink = async (
	pathname: string,
	isShort = false,
	deeplinkAction?: DeeplinkAction
) => {
	if (!_branch) {
		_branch = new Branch();
	}

	return _branch.getBranchLink(pathname, isShort, deeplinkAction);
};

export const deeplinkIntoAppUsingBranch = async (
	pathname: string,
	isShort = false,
	deeplinkAction?: DeeplinkAction
) => {
	const generatedLink = await getBranchDeepLink(pathname, isShort, deeplinkAction);
	window.location.href = generatedLink;
};
