import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

import getTelcos from '@anghami/neogateway/dist/endpoints/getTelcos';
import postMsisdnAuthenticate from '@anghami/neogateway/dist/endpoints/postMsisdnAuthenticate';
import postMsisdnLogin from '@anghami/neogateway/dist/endpoints/postMsisdnLogin';

import { environment } from '../../../env/environment';
import GenericButton from '../GenericButton';
import OTPInput from '@components/otpinput';

import { doAuthentication } from '../../../helpers/authenticate';
import useInterval from '../../../hooks/useInterval';

import styles from './styles.module.scss';
import { AmplitudeEvents } from '@anghami/neoanalytics/src/enums/events';
import reportAmplitudeEvent from '@helpers/analytics';
import { gateway } from '@helpers/neogateway';

enum Steps {
	error = 0,
	phoneInput = 1,
	OTPInput = 2
}

const AR_LOGO = 'logos/NEW_LOGO_HORIZONTAL_AR.png';
const EN_LOGO = 'new-anghami-logos/header-logo-fullblack.png';

export default function PhoneLogin({
	queryParams,
	onModalChange,
	onSuccess = (res) => res,
	onError = (error) => error
}) {
	const [step, setStep] = useState(null);
	const [telcos, setTelcos] = useState(null);
	const [selectedTel, setSelectedTel] = useState(null);
	const [showPopup, setShowPopup] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [retryTime, setRetryTime] = useState(0);
	const {
		t,
		i18n: { language: lang }
	} = useTranslation();

	useInterval(() => {
		if (retryTime > 0) {
			setRetryTime(retryTime - 1);
		}
	}, 1000);

	let renderStep = null;

	const handleTelChange = (e) => {
		const tel = telcos.telcos.find((tel) => e.target.value === tel.planid);
		setSelectedTel(tel);
	};

	const handlePhoneSubmit = (e, retryCall = false) => {
		e.preventDefault();

		if (phoneNumber.length < 3) {
			setError({
				message: ''
			});
			return;
		}

		if (retryTime !== 0) {
			return;
		}

		reportAmplitudeEvent(AmplitudeEvents.submitPhone, {});

		setLoading(true);
		gateway
		.callEndpoint(postMsisdnLogin, {
			telco: selectedTel.returnname,
			msisdn: Number(selectedTel.prefix + phoneNumber),
			call: retryCall ? 1 : 0
		})
		.then(({ data: res }) => {
			setLoading(false);
			setRetryTime(60);
			if (res.error) {
				setError(res.error);
				return;
			}

			setStep(Steps.OTPInput);
		})
		.catch((_) => {
			setLoading(false);
			setError({ message: t('Oops, something went wrong, please refresh the page') });
		});
	};

	const handleOTPChange = (value) => {
		if (value.length === 4) {
			// if the user filled all the 4 numbers
			reportAmplitudeEvent(AmplitudeEvents.submitVeritifcationCode, {
				code: value
			});

			setLoading(true);
			gateway
			.callEndpoint(postMsisdnAuthenticate, {
				code: value,
				operator: selectedTel.returnname,
				msisdn: Number(selectedTel.prefix + phoneNumber)
			})
			.then(({ data: res }) => {
				setLoading(false);
				if (res.error) {
					setError(res.error);
					return;
				}

				doAuthentication('an', {
					...res.forcelogin
				})
				.then(({ data: res }) => {
					setLoading(false);
					setShowPopup(false);
					onSuccess(res);
					reportAmplitudeEvent(AmplitudeEvents.loggedInWithPhone, {});
					reportAmplitudeEvent(AmplitudeEvents.loginSuccessful, {
						method: 'phone'
					});
				})
				.catch((err) => {
					setError(error);
					onError(err);
					reportAmplitudeEvent(AmplitudeEvents.loginError, {
						method: 'phone'
					});
				});
			})
			.catch((error) => {
				setLoading(false);
				setError(error);
				onError(error);
			});
		}
	};

	useEffect(() => {
		onModalChange(showPopup);
		if (step !== Steps.error && !showPopup) {
			setError(null);
		}
	}, [showPopup]);

	switch (step) {
		case Steps.phoneInput:
			renderStep = (
				<>
					<div className={`${styles['PhoneLogin-number']} ${error && 'error'}`}>
						<div className={styles['PhoneLogin-number-country']}>
							<select
								className={styles['PhoneLogin-number-country-selector']}
								onChange={handleTelChange}
							>
								{telcos.telcos.map((tel) => (
									<option key={tel.planid} value={tel.planid}>
										{tel.operatorname}
									</option>
								))}
							</select>
							{selectedTel && (
								<div className={styles['PhoneLogin-number-country-code']}>
									<img src={selectedTel.image} alt={selectedTel.operatorname} />
									<p>+{selectedTel.prefix}</p>
								</div>
							)}
						</div>
						<form className={styles['PhoneLogin-number-form']} onSubmit={handlePhoneSubmit}>
							<input
								required
								autoFocus
								className={styles['PhoneLogin-number-form-input']}
								type="number"
								placeholder={t('Please enter your mobile number')}
								value={phoneNumber}
								onChange={(e) => {
									setError(null);
									setPhoneNumber(e.target.value);
								}}
							/>
						</form>
					</div>
					{error?.message && (
						<p className={styles['PhoneLogin-number-error-message']}>{error.message}</p>
					)}
					<GenericButton
						isLoading={loading}
						className={styles['PhoneLogin-popup-btn']}
						onClick={handlePhoneSubmit}
					>
						{t('Continue')}
					</GenericButton>
				</>
			);
			break;
		case Steps.OTPInput:
			renderStep = (
				<div className={styles['PhoneLogin-otp']}>
					<p className={styles['PhoneLogin-otp-title']}>{t('Verify your phone number')}</p>
					<p className={styles['PhoneLogin-otp-subtitle']}>
						{t('You will receive an SMS to your phone shortly')}
					</p>

					<OTPInput onSubmit={handleOTPChange} />

					{error?.message && (
						<p className={styles['PhoneLogin-number-error-message']}>{error.message}</p>
					)}
					<p className={styles['PhoneLogin-otp-subtitle']}>
						{t('Didn\'t receive the code yet?')}{' '}
						{retryTime !== 0 && (
							<span>
								{t('Retry in')} {retryTime}
							</span>
						)}
					</p>
					<div className={styles['PhoneLogin-otp-btns']}>
						<p
							className={`${styles['PhoneLogin-otp-btns-btn']} ${
								retryTime !== 0 && styles['PhoneLogin-otp-btns-btn-disabled']
							}`}
							onClick={(e) => {
								handlePhoneSubmit(e, false);
							}}
						>
							{t('Resend')}
						</p>
						<p
							className={`${styles['PhoneLogin-otp-btns-btn']} ${
								retryTime !== 0 && styles['PhoneLogin-otp-btns-btn-disabled']
							}`}
							onClick={(e) => {
								handlePhoneSubmit(e, true);
							}}
						>
							{t('Call me')}
						</p>
					</div>
				</div>
			);
			break;

		case Steps.error:
			renderStep = error?.message && (
				<p className={styles['PhoneLogin-number-error-message']}>{error.message}</p>
			);
			break;

		default:
			renderStep = <p>Invalid step</p>;
	}

	return (
		<>
			<div className={styles.PhoneLogin}>
				<GenericButton
					icon="phone"
					className={styles['PhoneLogin-btn']}
					onClick={() => {
						reportAmplitudeEvent(AmplitudeEvents.tapPhoneSignup, {});
						gateway
						.callEndpoint(getTelcos, queryParams)
						.then(({ data: telcos }) => {
							if (telcos.telcos.length === 0) {
								setError({ message: t('Oops, something went wrong, please refresh the page') });
								setShowPopup(true);
								setStep(Steps.error);
								return;
							}

							setSelectedTel(telcos.telcos[0]);
							setTelcos(telcos);
							setShowPopup(true);
							setStep(Steps.phoneInput);
						})
						.catch(() => {
							setStep(3);
						});
					}}
				>
					{t('Continue with phone number')}
				</GenericButton>
			</div>

			{showPopup && (
				<>
					<div className={styles['PhoneLogin-popup'] + ' animate-fadeIn'}>
						<img
							className={styles['PhoneLogin-popup-logo']}
							alt="Anghami"
							src={`${environment.web_cdn_url}web/assets/img/${lang === 'ar' ? AR_LOGO : EN_LOGO}`}
						/>

						{step !== Steps.OTPInput && (
							<p className={styles['PhoneLogin-popup-title']}>{t('Login_now_web')}</p>
						)}

						{renderStep}

						<p
							className={styles['PhoneLogin-popup-back-btn']}
							onClick={() => {
								setShowPopup(false);
							}}
						>
							{t('back')}
						</p>
					</div>
					<div
						className={styles['PhoneLogin-popup-overlay']}
						onClick={() => {
							setShowPopup(false);
						}}
					/>
				</>
			)}
		</>
	);
}

PhoneLogin.propTypes = {
	queryParams: propTypes.object,
	onModalChange: propTypes.func,
	onSuccess: propTypes.func,
	onError: propTypes.func
};
