import { useTranslation } from 'next-i18next';
import React, { useContext } from 'react';
import { DialogComponent } from '../dialog/dialog';
import postAccountRestore from '@anghami/neogateway/dist/endpoints/postAccountRestore';
import { userContext } from '@components/auth/auth.context';
import { gateway } from '@helpers/neogateway';

enum DialogButtonsType {
	confirm = 'confirm',
	cancel = 'cancel'
}

const AccountRecoverModal = () => {
	const { t } = useTranslation();
	const { showAccountRecoverModal, logout } = useContext(userContext);

	const dialogData = {
		displaymode: 'custom',
		data: {
			id: 0
		},
		head: {
			text: t('Account_reactivation_title')
		},
		warning: false,
		body: {
			text: t('account_reactivation_message')
		},
		buttons: [
			{
				label: t('confirm'),
				styles: {
					color: '#8d00f5',
					backgroundColor: 'white',
					border: '1px solid #8d00f5',
					fontSize: '0.85em',
					padding: '0.75em 2em'
				},
				type: DialogButtonsType.confirm
			},
			{
				label: t('Cancel'),
				styles: {
					color: 'white',
					backgroundColor: '#8d00f5',
					fontSize: '0.9em',
					padding: '0.8em 2em'
				},
				type: DialogButtonsType.cancel
			}
		]
	};

	const handleOnDialogClick = ({ type }: { type: DialogButtonsType }) => {
		if (type === DialogButtonsType.cancel) {
			showAccountRecoverModal(false);
			logout();
		} else {
			gateway
			.callEndpoint(postAccountRestore, {})
			.then(() => {
				window.location.reload();
				showAccountRecoverModal(false);
			})
			.catch(() => {
				console.log('error');
			});
		}
	};

	return <DialogComponent data={dialogData} isIframe={false} onButtonClick={handleOnDialogClick} />;
};

export default AccountRecoverModal;
